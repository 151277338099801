import sekanjabin from "/src/assets/images/Sekanjabin_2.png";
import posca from "/src/assets/images/posca_1.png";
import switchel from "/src/assets/images/switchel_2.png";
import disc_sekanjabin from "/src/assets/images/disc_sekanjabin.png";
import disc_posca from "/src/assets/images/disc_posca.png";
import disc_switchel from "/src/assets/images/disc_switchel.png";
import logo_sekanjabin from "/src/assets/images/logo_sekanjabin.png";
import logo_posca from "/src/assets/images/logo_posca.png";
import logo_switchel from "/src/assets/images/logo_switchel.png";

import cardamom from "/src/assets/images/cardamom.png";
import apple from "/src/assets/images/apple.png";
import syrup from "/src/assets/images/syrup.png";
import maple from "/src/assets/images/maple.png";
import salt from "/src/assets/images/salt.png";
import pepper from "/src/assets/images/peppers.png";
import chili from "/src/assets/images/chili.png";
import citrus from "/src/assets/images/citrus.png";
import ginger from "/src/assets/images/ginger.png";
import mint from "/src/assets/images/mint.png";

import swords from "/src/assets/images/swords.png";
import roman from "/src/assets/images/roman.png";
import chalice from "/src/assets/images/chalice-gold.png";
import shield from "/src/assets/images/shield.png";
import chaliceDark from "/src/assets/images/chalice-dark.png";
import iranian from "/src/assets/images/iranian.png";
import hat from "/src/assets/images/hat.png";
import chaliceHandle from "/src/assets/images/chalice-handle.png";
import axe from "/src/assets/images/axe.png";

import switchelBox from "/src/assets/images/switchel-box.png";
import sekanjabinBox from "/src/assets/images/sekanjabin-box.png";
import poscaBox from "/src/assets/images/posca-box.png";
import multiBox from "/src/assets/images/try-them-all.png";

export const data = {
	landing: {
		hero: {
			title: "Yesterade<br>To<br>Slay Today",
			description:
				"Once upon a long time ago — way before Summer Fridays and smart phones — life itself was a very, very dangerous sport. Well, what powered the past is back. All organic and available exclusively on Amazon.",
			cans: [
				{
					center: switchel,
					disc: disc_switchel,
					ingredients: [ginger, salt, maple, apple, citrus],
					link: "/switchel",
				},
				{
					center: posca,
					disc: disc_posca,
					ingredients: [pepper, salt, maple, apple, cardamom],
					link: "/posca",
				},
				{
					center: sekanjabin,
					disc: disc_sekanjabin,
					ingredients: [mint, salt, maple, apple, chili],
					link: "/sekanjabin",
				},
			],
		},
		video: {
			url: "Video",
		},
		threeup: {
			title: "Introducing the elixirs of our ancestors:",
			products: [
				{
					center: sekanjabin,
					disc: disc_sekanjabin,
					title: "Sekanjabin",
					profile: "Mint & Chili",
					description: "The Official Refresher<br>of Ancient Persia",
					link: "/sekanjabin",
					amazon:
						"https://www.amazon.com/dp/B0CKRYQPKY?maas=maas_adg_A237F94FC660340F45E5A272CE0840D6_afap_abs&ref_=aa_maas&tag=maas",
					ingredients: [mint, salt, maple, apple, chili],
				},
				{
					center: posca,
					disc: disc_posca,
					title: "Posca",
					profile: "Cayenne & Cardamom",
					description: "The Sports Drink<br>of the Roman Empire",
					link: "/posca",
					amazon:
						"https://www.amazon.com/dp/B0CKRXMT8M?maas=maas_adg_04890CF6868AD591BEBD9B1B773106A6_afap_abs&ref_=aa_maas&tag=maas",
					ingredients: [pepper, salt, maple, apple, cardamom],
				},
				{
					center: switchel,
					disc: disc_switchel,
					title: "Switchel",
					profile: "Citrus & Ginger",
					description: "The Fire-up for American<br>Frontiersman and Farmers",
					link: "/switchel",
					amazon:
						"https://www.amazon.com/dp/B0CKRXV1YV?maas=maas_adg_DD30A0B2C17A3E2FC5FAECD7B6A72B5F_afap_abs&ref_=aa_maas&tag=maas",
					ingredients: [ginger, salt, maple, apple, citrus],
				},
			],
		},
		product: {
			title: "Try them all",
			image: multiBox,
			description: "Can’t decide what to try?<br>Every flave's your fave?",
			link: "https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas",
		},
		newsletter: {
			title: "If you can read <i>and</i> write.",
			description:
				"Trade your email for so much knowledge about the brand and how to seize special discounts and exclusive offers, everyone will think you're a witch",
		},
	},
	posca: {
		hero: {
			title: "Posca",
			logo: logo_posca,
			description:
				"Posca was created thousands of years ago from all natural ingredients as the original “sports drink” of the Roman Army. So, you know, probably enough power for whatever it is you’ve got going on.",
			quotes: [
				{
					quote:
						"There are great virtues in the harshness of posca, without which one cannot lead a good life.",
					author: "Pliny The Elder  (Rome, Italy 77 A.D.)",
				},
				{
					quote: "The perfect potion for after my afternoon pilates class.",
					author: "Carly (Troy, MI 2022 A.D.)",
				},
			],
			hits: [
				"The Alphabet",
				"Indoor Plumbing",
				"Propaganda",
				"Postal System",
				"Siege Warfare",
				"The First Highways",
			],
		},
		can: {
			center: posca,
			spin: "https://ingampam.sirv.com/AD_CanSpins_04/Posca.spin",
			disc: disc_posca,
			ingredients: [pepper, salt, maple, apple, cardamom],
			symbols: [swords, roman, chalice],
		},
		ingredients: {
			title: "Naturally strong, like a Roman Soldier",
			items: [
				{
					name: "Cardamom",
					img: cardamom,
				},
				{
					name: "Apple Cider Vinegar",
					img: apple,
				},
				{
					name: "Maple Syrup",
					img: syrup,
				},
				{
					name: "Pink Himalayan Salt",
					img: salt,
				},
				{
					name: "Cayenne Pepper",
					img: pepper,
				},
			],
			descriptions: [
				"Antioxidant <span>Rich</span>",
				"Ultra <span>Hydrating</span>",
				"Strengthens <span>Immunity</span>",
				"Promotes <span>Gut Health</span>",
			],
		},
		availability: [
			{
				title: "16oz Cans",
				img: poscaBox,
				link: "https://www.amazon.com/dp/B0CKRXMT8M?maas=maas_adg_04890CF6868AD591BEBD9B1B773106A6_afap_abs&ref_=aa_maas&tag=maas",
			},
			{
				title: "Variety Pack",
				img: multiBox,
				link: "https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas",
			},
		],
	},
	sekanjabin: {
		hero: {
			title: "Sekanjabin",
			logo: logo_sekanjabin,
			description:
				"Sekanjabin was created thousands of years ago from all natural ingredients as a refreshing “recovery drink” for the folks who braved mythical conditions to build a little thing called “civilization” in the Middle East. In other words, yes, it should revive you just fine too.",
			quotes: [
				{
					quote:
						"It provokes urine, expels gravel in the reins of kidneys; a dram therof given in Sekanjabin, after some walking or stirring of the body.",
					author:
						"The Complete Herbal: The Cure of all Disorders Incident to Mankind, 1850",
				},
				{
					quote:
						"Still not sure how to pronounce it, but it’s my jam after a summer run with the crisp and spicy mint chili.",
					author: "Cleo, Brooklyn, 2023",
				},
			],
			hits: [
				"Refrigeration",
				"Special Forces",
				"The Highway",
				"Birthday Parties",
				"Guitars",
				'The word "paradise"',
			],
		},
		can: {
			center: sekanjabin,
			spin: "https://ingampam.sirv.com/AD_CanSpins_04/Sekanjabin.spin",
			disc: disc_sekanjabin,
			ingredients: [mint, salt, maple, apple, chili],
			symbols: [shield, chaliceDark, iranian],
		},
		ingredients: {
			title: "It's what made cyrus the great",
			items: [
				{
					name: "Mint",
					img: mint,
				},
				{
					name: "Apple Cider Vinegar",
					img: apple,
				},
				{
					name: "Maple Syrup",
					img: syrup,
				},
				{
					name: "Pink Himalayan Salt",
					img: salt,
				},
				{
					name: "Aleppo Chili",
					img: chili,
				},
			],
			descriptions: [
				"Antioxidant <span>Rich</span>",
				"Ultra <span>Hydrating</span>",
				"Strengthens <span>Immunity</span>",
				"Promotes <span>Gut Health</span>",
			],
		},
		availability: [
			{
				title: "16oz Cans",
				img: sekanjabinBox,
				link: "https://www.amazon.com/dp/B0CKRYQPKY?maas=maas_adg_A237F94FC660340F45E5A272CE0840D6_afap_abs&ref_=aa_maas&tag=maas",
			},
			{
				title: "Variety Pack",
				img: multiBox,
				link: "https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas",
			},
		],
	},
	switchel: {
		hero: {
			title: "Switchel",
			logo: logo_switchel,
			description:
				'Switchel was created hundreds of years ago as the all- natural "sports drink" of frontiersman and farmers who fought off bears during their their down time. Put another way, yeah, it should be fuel enough to get you through today.',
			quotes: [
				{
					quote: "Fear’s the spice that makes it interesting to go ahead.",
					author: "Daniel Boone, Frankfort, KY",
				},
				{
					quote:
						"Switchel’s spice quenches and causes my thirst. Makes me want to chug it.",
					author: "Danny, Boone, NC",
				},
			],
			hits: [
				"Gravity",
				"Darwin",
				"The Dictionary",
				"Lincoln",
				"Declaration of Independence",
				"The National Parks",
			],
		},
		can: {
			center: switchel,
			spin: "https://ingampam.sirv.com/AD_CanSpins_04/Switchel.spin",
			disc: disc_switchel,
			ingredients: [ginger, salt, maple, apple, citrus],
			symbols: [hat, chaliceHandle, axe],
		},
		ingredients: {
			title: "DAVEY CROCKET’S FAVORITE FUEL",
			items: [
				{
					name: "Citrus",
					img: citrus,
				},
				{
					name: "Apple Cider Vinegar",
					img: apple,
				},
				{
					name: "Maple Syrup",
					img: syrup,
				},
				{
					name: "Pink Himalayan Salt",
					img: salt,
				},
				{
					name: "Ginger",
					img: ginger,
				},
			],
			descriptions: [
				"<span>Antioxidant</span> Rich",
				"<span>Ultra</span> Hydrating",
				"<span>Strengthens</span> Immunity",
				"<span>Promotes</span> Gut Health",
			],
		},
		availability: [
			{
				title: "16oz Cans",
				img: switchelBox,
				link: "https://www.amazon.com/dp/B0CKRXV1YV?maas=maas_adg_DD30A0B2C17A3E2FC5FAECD7B6A72B5F_afap_abs&ref_=aa_maas&tag=maas",
			},
			{
				title: "Variety Pack",
				img: multiBox,
				link: "https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas",
			},
		],
	},
};
