import React, { useRef, useState, useEffect } from "react";
import Preview from "../../assets/images/AD_Preview.gif";

const Video = ({ url }) => {
	const videoRef = useRef(null);
	const [scale, setScale] = useState(false);
	const [hideCover, setHideCover] = useState(false);

	const handleScroll = () => {
		const pageScroll = window.pageYOffset;
		const pageHeight = window.innerHeight;
		const videoTop = videoRef.current.offsetTop;
		const videoHeight = videoRef.current.offsetHeight;
		const enterScreen = videoTop - pageHeight;
		const enterValue = 0.5;
		const exitScreen = videoTop;
		const exitValue = 1;

		const minView = enterScreen <= pageScroll;
		const maxView = exitScreen < pageScroll;

		const slope = (exitValue - enterValue) / (exitScreen - enterScreen);
		const intercept = enterValue - slope * enterScreen;

		if (minView && !maxView) {
			setScale(pageScroll * slope + intercept);
		} else if (minView && maxView) {
			setScale(exitValue);
		} else {
			setScale(enterValue);
		}
	};

	useEffect(() => {
		if (window.innerWidth > 768) {
			handleScroll();
			window.addEventListener("scroll", handleScroll, {
				passive: true,
			});

			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, []);

	return (
		<div
			ref={videoRef}
			className="video"
			style={{
				transform: `scale(${scale})`,
			}}
		>
			{!hideCover && (
				<div
					className="video__cover"
					style={{ backgroundImage: `url(${Preview})` }}
					onClick={() => setHideCover(true)}
				>
					<svg
						viewBox="0 0 40 34"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M0.000716632 14.3365C-0.0892192 27.4735 8.29698 33.0992 18.9094 33.2762C30.5111 33.3204 39.9094 26.0581 39.9994 14.3365C40.0893 2.74766 30.3762 -0.209841 18.9094 0.0113209C7.03788 0.276715 0.0906524 2.5265 0.000716632 14.3365ZM13.978 10.6645C14.0266 10.1565 14.0753 9.17178 14.0934 8.80467L14.095 8.7723C14.1619 7.55591 15.4491 8.13031 16.3017 8.60335C17.1234 9.05682 17.9492 9.4292 18.7418 9.78659C20.4 10.5343 21.9127 11.2164 22.9383 12.4384C23.7045 13.3513 25.2892 13.9532 26.6692 14.4773C28.0191 14.9899 29.173 15.4281 29.173 16.0103C29.173 17.0614 24.5921 19.2152 23.3461 19.8009C23.1966 19.8712 23.095 19.919 23.0553 19.9395C23.0374 19.9487 23.0121 19.9616 22.98 19.978C22.346 20.3011 19.0511 21.9807 17.5554 23.5887C17.2818 23.8842 16.8857 24.0453 16.4843 24.2085C16.1746 24.3344 15.8616 24.4617 15.5995 24.653C15.1816 24.9571 14.0616 24.011 14.0783 23.8083C14.1264 23.2869 14.0755 21.4413 14.0152 19.2553C13.929 16.1284 13.8235 12.3051 13.9613 10.6645H13.978Z"
							fill="currentColor"
						/>
					</svg>
				</div>
			)}
			<iframe
				src={`https://player.vimeo.com/video/748138280?&autoplay=${
					hideCover ? "1" : "0"
				}&loop=1h=a673d88768&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479/embed`}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			></iframe>
		</div>
	);
};

export default Video;
