import React from "react";

export const CarouselPrevIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 465 920"
			width="24px"
			height="24px"
			className="crt-icon-left-open-big"
		>
			<path
				transform="scale(1,-1) translate(0,-850)"
				d="M452-20q26-26 0-48-26-26-48 0l-392 394q-24 24 0 50l392 394q22 26 48 0 26-22 0-48l-358-372z"
			></path>
		</svg>
	);
};

export const CarouselNextIcon = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 465 920"
			width="24px"
			height="24px"
			className="crt-icon-right-open-big"
		>
			<path
				transform="scale(1,-1) translate(0,-850)"
				d="M13-20l358 370-358 372q-26 26 0 48 26 26 48 0l392-394q24-26 0-50l-392-394q-22-26-48 0-26 22 0 48z"
			></path>
		</svg>
	);
};
