import React from "react";
import { Link } from "gatsby";

// Assets
import doRight from "../../assets/images/do-right.png";

// Components
import StoreLocatorButton from "components/Global/StoreLocatorButton";

const StoreLocatorCTA = () => {
	return (
		<div className="store-locator-cta">
			<h2>
				FIND SHOPPES <br />
				WITH ANCIENT DRINKS
			</h2>

			<div className="store-locator-cta-image">
				<img src={doRight} />
			</div>

			<p className="store-locator-description">
				<em>
					Discover where you can bring what powered the past to your present.
				</em>
			</p>

			<div className="store-locator-button-container">
				<StoreLocatorButton location={"desktop"} />
			</div>

			{/* <Link
				to="/store-locator"
				className="store-locator-button amazon-button"
				// onClick={() => {
				// if (typeof window !== undefined) {
				// 	window.dataLayer.push({
				// 		event: "button_click",
				// 		button_name: `Store Locator CTA`,
				// 	});
				// }
				// }}
			>
				Store Locator
			</Link> */}
		</div>
	);
};

export default StoreLocatorCTA;
