import React from "react";
import Slider from "react-slick";

// Assets
import reviewOne from "../../assets/images/review-01.svg";
import reviewTwo from "../../assets/images/review-02.svg";
import reviewThree from "../../assets/images/review-03.svg";
import reviewFour from "../../assets/images/review-04.svg";

// Icons
import { CarouselNextIcon, CarouselPrevIcon } from "../icons/carousel-icons";

function NextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<button type="button" className={className} onClick={onClick}>
			<CarouselNextIcon />
		</button>
	);
}

function PrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<button type="button" className={className} onClick={onClick}>
			<CarouselPrevIcon />
		</button>
	);
}

const Reviews = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 2000,
		pauseOnHover: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: true,
		centerMode: false,
		centerPadding: "20px",
		autoplay: true,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			// {
			// 	breakpoint: 1100,
			// 	settings: {
			// 		slidesToShow: 3,
			// 		slidesToScroll: 1,
			// 		centerMode: true,
			// 		autoplay: true,
			// 		centerPadding: "20px",
			// 	},
			// },
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					// centerMode: true,
					centerPadding: "20px",
					autoplay: true,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					centerPadding: "20px",
					autoplay: true,
				},
			},
		],
	};

	return (
		<div className="reviews-container">
			<div className="reviews-inner-wrapper">
				<div className="title-container">
					<h2>Ancient Reviews</h2>
					<div className="line" />
				</div>

				<div className="reviews-inner-container">
					<Slider {...settings}>
						<div className="single-review">
							<img
								src={reviewOne}
								alt="I just love these drinks. There is really nothing like them. They have a wonderful tangy quality from the apple cider vinegar."
							/>
						</div>
						<div className="single-review">
							<img
								src={reviewTwo}
								alt="So smooth and not acidic at all. Perfect balance of ingredients and you feel great from them!"
							/>
						</div>
						<div className="single-review">
							<img
								src={reviewThree}
								alt="I really like these drinks. They' are all-natural and organic, so no artificial colors or chemicals like you find in the big-name sport drinks (not naming names). The flavor is different from other sport drinks too, and really refreshing. These aren't carbonated and aren't 'energy drinks' loaded with caffeine. Very good for hydrating after a workout or just something different to drink when you feel like a change."
							/>
						</div>
						<div className="single-review">
							<img
								src={reviewFour}
								alt="I just love these drinks. There is really nothing like them. They have a wonderful tangy quality from the apple cider vinegar"
							/>
						</div>
					</Slider>

					<div className="bottom-border" />
				</div>
			</div>
		</div>
	);
};

export default Reviews;
