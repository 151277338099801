import React from "react";
import { SEO } from "components/seo";

import Layout from "components/Global/Layout";

import Hero from "components/Landing/Hero";
import Video from "components/Landing/Video";
import ThreeUp from "components/Landing/ThreeUp";
import FAQ from "components/Landing/FAQ";
import NewsletterPopupWrapper from "components/Global/NewsletterPopupWrapper";
import Newsletter from "components/Landing/Newsletter";
import Product from "components/Landing/Product";
import Reviews from "components/Landing/Reviews";
import SocialMediaCTA from "components/Landing/SocialMediaCTA";
import StoreLocatorCTA from "components/Landing/StoreLocatorCTA";
import { data } from "../../content/data";

const IndexPage = () => {
	return (
		<Layout
			pageName="home"
			amazon="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas"
		>
			<Hero
				title={data.landing.hero.title}
				description={data.landing.hero.description}
				cans={data.landing.hero.cans}
			/>
			<Video url={data.landing.video.url} />
			<ThreeUp
				title={data.landing.threeup.title}
				products={data.landing.threeup.products}
			/>
			<StoreLocatorCTA />
			<Product product={data.landing.product} />

			<Reviews />
			<SocialMediaCTA />
			<FAQ />
			<Newsletter
				title={data.landing.newsletter.title}
				description={data.landing.newsletter.description}
			/>
			<NewsletterPopupWrapper />
		</Layout>
	);
};

export default IndexPage;

export const Head = () => <SEO />;
