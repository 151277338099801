import React, { useState, useEffect, useRef } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { useSessionStorage } from "react-use";

// Assets
import posca from "../../assets/images/posca_1-small.png";
import switchel from "../../assets/images/switchel_2-small.png";
import sekanjabin from "../../assets/images/Sekanjabin_2-small.png";

// Icons
import {
	NewsletterIcons,
	NewsletterCloseIcon,
} from "../icons/newsletter-icons";

// Images
import doLeft from "../../assets/images/do-left.png";

const NewsletterPopup = ({ shouldShow }) => {
	const [email, setEmail] = useState("");
	const [result, setResult] = useState();
	const [isNewsletterOpen, setIsNewsletterOpen] = useSessionStorage(
		"isNewsletterOpen",
		true
	);
	const [isVisible, setIsVisible] = useState(false);
	const popupRef = useRef(null);

	const handleSubmit = (e) => {
		e.preventDefault();
		addToMailchimp(email).then((data) => {
			setResult(data);
		});
	};

	useEffect(() => {
		let animationFrame;
		if (shouldShow && isNewsletterOpen) {
			// Use requestAnimationFrame for smoother animation
			animationFrame = requestAnimationFrame(() => {
				setIsVisible(true);
			});
		}
		return () => cancelAnimationFrame(animationFrame);
	}, [shouldShow, isNewsletterOpen]);

	useEffect(() => {
		if (popupRef.current && isVisible) {
			popupRef.current.style.display = "block";
			void popupRef.current.offsetHeight; // Trigger reflow
			popupRef.current.classList.add("visible");
		}
	}, [isVisible]);

	useEffect(() => {
		// Preload images
		const imageUrls = [posca, switchel, sekanjabin, doLeft];
		imageUrls.forEach((url) => {
			const img = new Image();
			img.src = url;
		});
	}, []);

	const handleClose = () => {
		if (popupRef.current) {
			popupRef.current.classList.remove("visible");
			// Wait for transition to complete before hiding
			setTimeout(() => {
				setIsNewsletterOpen(false);
				setIsVisible(false);
			}, 500); // Match this with your transition duration
		}
	};

	if (!shouldShow || !isNewsletterOpen) return null;

	return (
		<div ref={popupRef} className="newsletter-pop-up-container">
			<div className="inner-newsletter">
				<div className="outer-cans-container">
					<div className="spacer" />

					<div className="cans-container">
						<img
							className="can can-1"
							src={posca}
							alt="Posca Can"
							loading="eager"
						/>
						<img
							className="can can-2"
							src={sekanjabin}
							alt="Sekanjabin Can"
							loading="eager"
						/>
						<img
							className="can can-3"
							src={switchel}
							alt="Switchel Can"
							loading="eager"
						/>
					</div>

					<button type="button" onClick={handleClose}>
						<NewsletterCloseIcon />
					</button>
				</div>

				{result?.result === "success" ? (
					<h2>Thank you</h2>
				) : (
					<div className="title-container">
						<h2>What Powered The Past Is Back</h2>
					</div>
				)}
				{result?.result === "success" ? (
					<p className="description">
						We'll send updates in ink on papyrus via carrier pigeon, or by
						email.
					</p>
				) : (
					<p className="description">
						Join Spartacus, Cyrus, Cleopatra and Davy Crockett as fans of the
						brand that gives you the sustained energy to fight off waves of
						invaders, the acuity to outwit witches and the recovery power to do
						it all again tomorrow.
					</p>
				)}

				<form
					className={`${result?.result === "success" && "newsletter__success"}`}
					onSubmit={handleSubmit}
				>
					<input
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Enter Your Electronic Mailing Address Here"
						value={email}
					/>
					<button type="submit" className="amazon-button">
						Ancient Me
					</button>
				</form>
			</div>

			<div className="hieroglyphic-container">
				<NewsletterIcons />
			</div>

			<div className="image-container">
				<img src={doLeft} alt="Sign up" loading="eager" />
			</div>
		</div>
	);
};

export default NewsletterPopup;
