import React, { useEffect, useState } from "react";
import NewsletterPopup from "components/Global/NewsletterPopup";

const NewsletterPopupWrapper = () => {
	const [shouldShowPopup, setShouldShowPopup] = useState(false);

	useEffect(() => {
		const popupShownThisSession = sessionStorage.getItem(
			"popupShownThisSession"
		);

		if (!popupShownThisSession) {
			// Delay showing the popup to allow for the fade-in effect
			const timer = setTimeout(() => {
				setShouldShowPopup(true);
				sessionStorage.setItem("popupShownThisSession", "true");
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, []);

	return <NewsletterPopup shouldShow={shouldShowPopup} />;
};

export default NewsletterPopupWrapper;
