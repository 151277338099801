import React, { useState, useEffect, useRef } from 'react';

import addToMailchimp from 'gatsby-plugin-mailchimp';
import Background from '../../assets/images/newsletter.png';

const Newsletter = ({ title, description }) => {
  const backgroundRef = useRef(null);
  const [email, setEmail] = useState('');
  const [result, setResult] = useState();
  const [distance, setDistance] = useState(false);

  const handleScroll = () => {
    const pageScroll = window.pageYOffset;
    const pageHeight = window.innerHeight;
    const backgroundTop = backgroundRef.current.offsetTop;
    const backgroundHeight = backgroundRef.current.offsetHeight;
    const enterScreen = backgroundTop - pageHeight;
    const enterValue = -100;
    const exitScreen = backgroundTop - backgroundHeight * 0.5;
    const exitValue = 0;

    const minView = enterScreen <= pageScroll;
    const maxView = exitScreen < pageScroll;

    const slope =
      (exitValue - enterValue) / (exitScreen - enterScreen);
    const intercept = enterValue - slope * enterScreen;

    if (minView && !maxView) {
      setDistance(pageScroll * slope + intercept);
    } else if (minView && maxView) {
      setDistance(exitValue);
    } else {
      setDistance(enterValue);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      handleScroll();
      window.addEventListener('scroll', handleScroll, {
        passive: true,
      });

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(email).then((data) => {
      setResult(data);
    });
  };

  return (
    <div className="newsletter">
      {result?.result === 'success' ? (
        <h2>Thank you</h2>
      ) : (
        <h2 dangerouslySetInnerHTML={{ __html: title }} />
      )}
      {result?.result === 'success' ? (
        <p className="newsletter__description">
          We'll send updates in ink on papyrus via carrier pigeon, or
          by email.
        </p>
      ) : (
        <p className="newsletter__description">{description}</p>
      )}

      <form
        className={`${result?.result === 'success' && 'newsletter__success'
          }`}
        onSubmit={handleSubmit}
      >
        <input
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          value={email}
        />
        <button type="submit">Submit</button>

        <img
          style={{ transform: `translateX(${distance}%)` }}
          ref={backgroundRef}
          src={Background}
          alt={title}
        />
      </form>
    </div>
  );
};

export default Newsletter;
