import React from 'react';
import { Link } from 'gatsby';

import Can from '../Product/Can';
import AmazonButton from '../Global/AmazonButton';

const ThreeUp = ({ title, products }) => {
  return (
    <div className="threeup">
      <h2>{title}</h2>

      <ul>
        {products.map((product, key) => (
          <li key={key} className={`threeup__${product.title}`}>
            <Link className="threeup__link" to={product.link}>
              <div className="threeup__can">
                <Can
                  center={product.center}
                  disc={product.disc}
                  ingredients={product.ingredients}
                />
              </div>

              <h3>{product.title}</h3>
              <h4>{product.profile}</h4>
              <h5
                dangerouslySetInnerHTML={{
                  __html: product.description,
                }}
              />
            </Link>
            <AmazonButton to={product.amazon} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ThreeUp;
