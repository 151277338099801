import React from "react";
import { Script } from "gatsby";

import posca from "../../assets/images/posca.png";
import poscaDisc from "../../assets/images/disc_posca.png";

import cardamom from "/src/assets/images/cardamom.png";
import apple from "/src/assets/images/apple.png";
import maple from "/src/assets/images/maple.png";
import salt from "/src/assets/images/salt.png";
import pepper from "/src/assets/images/peppers.png";
import chili from "/src/assets/images/chili.png";
import citrus from "/src/assets/images/citrus.png";
import ginger from "/src/assets/images/ginger.png";

const Can = ({ center, disc, ingredients, symbols, spin }) => {
  return (
    <div className="can">
      <div
        className={`can__wrapper ${spin ? "can__wrapper--spin" : ""}`}
        style={{ backgroundImage: `url(${disc})` }}
      >
        {spin ? (
          <div className="can__spin-wrapper Sirv" data-src={spin}></div>
        ) : (
          <img className="can__center" src={center} />
        )}

        <div className="can__ingredients">
          {ingredients.map((item, key) => (
            <img key={key} src={item} />
          ))}
        </div>

        {symbols && (
          <div className="can__symbols">
            {symbols.map((item, key) => (
              <img key={key} src={item} />
            ))}
          </div>
        )}
      </div>

      <Script id="sirv" src="https://scripts.sirv.com/sirv.js" />
    </div>
  );
};

export default Can;
