import React, { useEffect } from "react";
import { Link } from "gatsby";
import { useMedia } from "react-use";

const MobileLayout = () => {
	useEffect(() => {
		// Load the Curator script
		const script = document.createElement("script");
		script.src =
			"https://cdn.curator.io/published/b9c9e7d7-6f5d-47db-b668-2617213adee4_1wy2o0o6.js";
		script.async = true;
		script.charset = "UTF-8";
		document.body.appendChild(script);

		// Cleanup function to remove the script when component unmounts
		return () => {
			document.body.removeChild(script);
		};
	}, []); // Empty dependency array means this effect runs once on mount

	return (
		<div id="curator-feed-homepage-cta-mobile">
			<a href="https://curator.io" target="_blank" className="crt-logo crt-tag">
				Powered by Curator.io
			</a>
		</div>
	);
};

const DesktopLayout = () => {
	useEffect(() => {
		// Load the Curator script
		const script = document.createElement("script");
		script.src =
			"https://cdn.curator.io/published/b9c9e7d7-6f5d-47db-b668-2617213adee4_rwez327w.js";
		script.async = true;
		script.charset = "UTF-8";
		document.body.appendChild(script);

		// Cleanup function to remove the script when component unmounts
		return () => {
			document.body.removeChild(script);
		};
	}, []); // Empty dependency array means this effect runs once on mount

	useEffect(() => {
		const d = document;
		const s = d.createElement("script");
		s.type = "text/javascript";
		s.async = true;
		s.charset = "UTF-8";
		s.src =
			"https://cdn.curator.io/published/b9c9e7d7-6f5d-47db-b668-2617213adee4.js";
		const e = d.getElementsByTagName("script")[0];
		e.parentNode.insertBefore(s, e);
	}, []);

	return (
		<div id="curator-feed-homepage-cta">
			<a href="https://curator.io" target="_blank" className="crt-logo crt-tag">
				Powered by Curator.io
			</a>
		</div>
	);
};

const SocialMediaCTA = () => {
	const isDesktop = useMedia("(min-width: 480px)", false);

	return (
		<div className="social-media-cta">
			<div className="title-container">
				<div className="spacer" />

				<div>
					<h2>ANCIENTGRAM</h2>
				</div>

				<div>
					<Link to="/ancientgram" className="social-media-button amazon-button">
						Follow Us
					</Link>
				</div>
			</div>

			<div className="social-media-feed-container">
				{isDesktop ? <DesktopLayout /> : <MobileLayout />}
			</div>
		</div>
	);
};

export default SocialMediaCTA;
