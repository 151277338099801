import React, { useState } from "react";
import { CSSTransitionGroup } from "react-transition-group";

import herbs from "../../assets/images/herbs.png";
import salt from "../../assets/images/salt.png";
import apple from "../../assets/images/apple.png";
import syrup from "../../assets/images/syrup.png";
import USDA from "../../assets/images/usda-organic.png";
import YellowDisc from "../../assets/images/yellow-disc.png";
import YellowDiscLarge from "../../assets/images/yellow-disc-big.png";
import madeLeft from "../../assets/images/made-left.png";
import madeRight from "../../assets/images/made-right.png";
import tasteLeft from "../../assets/images/taste-left.png";
import tasteRight from "../../assets/images/taste-right.png";
import doLeft from "../../assets/images/do-left.png";
import doRight from "../../assets/images/do-right.png";
import column from "../../assets/images/column.png";
import posca from "../../assets/images/posca-can.png";
import switchel from "../../assets/images/switchel-can.png";
import sekanjabin from "../../assets/images/sekanjabin-can.png";
import box from "../../assets/images/multipack-box.png";

const FAQ = () => {
	const [isActiveSlide, setIsActiveSlide] = useState("1");

	return (
		<div className={`faq faq--${isActiveSlide}`}>
			<ul className="faq__titles">
				<li>
					<button onClick={() => setIsActiveSlide("1")}>
						<span>What is it</span>
						<span>made of?</span>
					</button>
				</li>

				<li>
					<button onClick={() => setIsActiveSlide("2")}>
						<span>What does it</span>
						<span>taste like?</span>
					</button>
				</li>

				<li>
					<button onClick={() => setIsActiveSlide("3")}>
						<span>What does</span>
						<span>it do?</span>
					</button>
				</li>
			</ul>

			<div className="faq__sections-wrapper">
				<CSSTransitionGroup
					transitionName="example"
					transitionEnterTimeout={500}
					transitionLeaveTimeout={1}
				>
					{isActiveSlide === "1" && (
						<div className="faq__section faq__section--made-of">
							<div className="faq__main-content">
								<p className="faq__description">
									Recipies of yore, made for today.
								</p>

								<ul className="faq__ingredients">
									<li>
										<div className="faq__ingredients-img-wrapper">
											<img src={herbs} alt="Roots, Herbs, Spices and Fruits" />
										</div>
										<p>Roots, Herbs, Spices and Fruits</p>
									</li>

									<li>
										<div className="faq__ingredients-img-wrapper">
											<img src={salt} alt="Pink Himalyan Salt" />
										</div>
										<p>Pink Himalayan Salt</p>
									</li>

									<li>
										<div className="faq__ingredients-img-wrapper">
											<img src={apple} alt="Apple Cider Vinegar" />
										</div>
										<p>Apple Cider Vinegar</p>
									</li>

									<li>
										<div className="faq__ingredients-img-wrapper">
											<img src={syrup} alt="Maple Syrup" />
										</div>
										<p>Maple Syrup</p>
									</li>
								</ul>
							</div>

							<div className="faq__half-sections">
								<ul>
									<li>Caffeine Free</li>
									<li>Non-Alcoholic</li>
									<li>No Artificial Sweeteners</li>
									<li>No Synthetic Dyes</li>
									<li>No Eye of Newt</li>
								</ul>

								<div className="faq__usda">
									<img src={USDA} alt="USDA Organic" />

									<div>
										<p>Zero Plastic Packaging</p>
										<p>
											No can "sleeves" <br />
											for actual recyclability
										</p>
									</div>
								</div>

								<img
									className="faq__disc faq__disc--mobile"
									src={YellowDisc}
									alt="Yellow Disc"
								/>
								<img
									className="faq__disc faq__disc--desktop"
									src={YellowDiscLarge}
									alt="Yellow Disc"
								/>
							</div>

							<div className="faq__background-block faq__background-block--1">
								<div className="faq__background-img faq__background-img--left">
									<img src={madeLeft} />
								</div>

								<div className="faq__background-img faq__background-img--right">
									<img src={madeRight} />
								</div>
							</div>

							<div className="faq__column-wrapper">
								<img className="faq__column" src={column} alt="Column" />
								<img className="faq__box" src={box} alt="Multipack Box" />
								<img className="faq__can-1" src={posca} alt="Posca Can" />
								<img
									className="faq__can-2"
									src={sekanjabin}
									alt="Sekanjabin Can"
								/>
								<img className="faq__can-3" src={switchel} alt="Switchel Can" />
							</div>
						</div>
					)}
				</CSSTransitionGroup>

				<CSSTransitionGroup
					transitionName="example"
					transitionEnterTimeout={500}
					transitionLeaveTimeout={1}
				>
					{isActiveSlide === "2" && (
						<div className="faq__section faq__section--taste-like">
							<div className="faq__main-content">
								<p className="faq__description">
									Every flavor is unique, but they’re all...
								</p>

								<ul>
									<li className="faq__taste-like-line">
										A little <span>sour</span>
									</li>
									<li className="faq__taste-like-line">
										A little <span>sweet</span>
									</li>
									<li className="faq__taste-like-line">
										A little <span>tang</span>
									</li>
									<li className="faq__taste-like-line">
										A little <span>heat</span>
									</li>
								</ul>
							</div>

							<div className="faq__background-block faq__background-block--2">
								<div className="faq__background-img faq__background-img--left">
									<img src={tasteLeft} />
								</div>

								<div className="faq__background-img faq__background-img--right">
									<img src={tasteRight} />
								</div>
							</div>
						</div>
					)}
				</CSSTransitionGroup>

				<CSSTransitionGroup
					transitionName="example"
					transitionEnterTimeout={500}
					transitionLeaveTimeout={1}
				>
					{isActiveSlide === "3" && (
						<div className="faq__section faq__section--it-do">
							<div className="faq__main-content">
								<ul>
									<li className="faq__it-do-line">
										<span>Energy</span>
										<p>Organic herbs, spices and fruit to fire you up.</p>
									</li>
									<li className="faq__it-do-line">
										<span>Endurance</span>
										<p>Super-hydrating electrolytes to keep you going.</p>
									</li>
									<li className="faq__it-do-line">
										& <span>Recovery</span>
										<p>
											Antioxidants & probiotics
											<br />
											...to get you back out there
										</p>
									</li>
								</ul>
							</div>

							<div className="faq__background-block faq__background-block--3">
								<div className="faq__background-img faq__background-img--left">
									<img src={doLeft} />
								</div>

								<div className="faq__background-img faq__background-img--right">
									<img src={doRight} />
								</div>
							</div>
						</div>
					)}
				</CSSTransitionGroup>
			</div>
		</div>
	);
};

export default FAQ;
