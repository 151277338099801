import React from "react";
import AmazonButton from "../Global/AmazonButton";

const Product = ({ product }) => {
  return (
    <div className="product">
      <h2>{product.title}</h2>
      <img className="product__image" src={product.image} alt={product.title} />
      <p dangerouslySetInnerHTML={{ __html: product.description }} />
      <AmazonButton to={product.link} />
    </div>
  );
};

export default Product;
